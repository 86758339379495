import { releaseCanvas } from 'mixtiles-web-common/services/canvasService'
import { logger } from '../services/logger'
import {
  calculateSmartCropFromUrl,
  ExternalError,
} from '../services/SmartCrop/smartCrop'
import { extractThumbDimensionsFromUrl } from '../components/PhotoWall/cloudinaryTransformations'
import { CROP_TYPE } from './cropUtils.consts'
import { fitCropToAllRatios } from './cropRatioUtils'
import type { Crop, Rect } from './cropUtils.types'
import type { Photo } from '../components/PhotoBank/PhotoBank.types'
import type { Tile } from '../components/TilesProvider/TilesProvider.types'

const SMART_CROP_TIMEOUT = 2500

/**
 * Calculates the CSS properties for the crop rect to be positioned and scaled correctly in the frame.
 */
export function calculateTransformForCrop({
  domFrameDimensions,
  domImageDimensions,
  relativeCropRect,
}: {
  domFrameDimensions: Pick<Rect, 'width' | 'height'>
  domImageDimensions: Pick<Rect, 'width' | 'height'>
  relativeCropRect: Crop
}) {
  // relativeCropRect contains values between 0 and 1, it allows us to translate the crop to any image size.
  const domImageCropRect = {
    width: domImageDimensions.width * relativeCropRect.width,
    height: domImageDimensions.height * relativeCropRect.height,
    x: domImageDimensions.width * relativeCropRect.x,
    y: domImageDimensions.height * relativeCropRect.y,
  }

  const scaleX = domFrameDimensions.width / domImageCropRect.width
  const scaleY = domFrameDimensions.height / domImageCropRect.height

  return {
    transformOrigin: 'top left',
    transform: `scale(${scaleX}, ${scaleY}) translate(-${domImageCropRect.x}px, -${domImageCropRect.y}px)`,
  }
}

export function calcImageDefaultCrop(
  imageWidth: number,
  imageHeight: number,
  targetRatio: number
) {
  if (!imageWidth || !imageHeight) {
    logger.info(
      'calcImageDefaultCrop: Invalid image width or height, returning null',
      { level: 'error', extra: { imageWidth, imageHeight, targetRatio } }
    )
    return null
  }
  const sourceRatio = imageWidth / imageHeight

  let width
  let height
  let x
  let y
  if (sourceRatio >= targetRatio) {
    width = imageWidth * (targetRatio / sourceRatio)
    height = imageHeight
    x = (imageWidth - width) / 2
    y = 0
  } else {
    width = imageWidth
    height = imageHeight * (sourceRatio / targetRatio)
    x = 0
    y = (imageHeight - height) / 2
  }

  return {
    width: Math.round(width),
    height: Math.round(height),
    x: Math.round(x),
    y: Math.round(y),
    cropType: CROP_TYPE.DEFAULT,
  }
}

export function normalizeCropParams(
  cropParams: Rect & { cropType?: Crop['cropType'] },
  imageDimensions: Pick<Rect, 'width' | 'height'>
) {
  return {
    x: cropParams.x / imageDimensions.width,
    y: cropParams.y / imageDimensions.height,
    width: cropParams.width / imageDimensions.width,
    height: cropParams.height / imageDimensions.height,
    cropType: cropParams.cropType ?? CROP_TYPE.DEFAULT,
  }
}

function cropImageOnCanvas(image: CanvasImageSource, crop: Rect) {
  const canvas = document.createElement('canvas')
  canvas.width = crop.width
  canvas.height = crop.height

  const ctx = canvas.getContext('2d')
  ctx?.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height
  )
  return canvas
}

export function cropImage(image: CanvasImageSource, crop: Rect) {
  const canvas = cropImageOnCanvas(image, crop)
  const croppedImageUrl = canvas.toDataURL()
  releaseCanvas(canvas)

  return croppedImageUrl
}

const CROPPED_IMAGE_CACHE: Record<string, any> = {}

export function cropImagePromise(image: HTMLImageElement, crop: Crop) {
  if (!image.src || image.src.length > 100) {
    logger.warning('cropImagePromise: Invalid image src', image.src)
  }
  const cacheKey = `${image.src}_${crop.x}_${crop.y}_${crop.width}_${crop.height}`
  if (CROPPED_IMAGE_CACHE[cacheKey]) {
    return CROPPED_IMAGE_CACHE[cacheKey]
  }

  const canvas = cropImageOnCanvas(image, crop)
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      try {
        const url = URL.createObjectURL(blob!)
        releaseCanvas(canvas)
        CROPPED_IMAGE_CACHE[cacheKey] = url
        resolve(url)
      } catch (error) {
        logger.error('Failed cropping image', error)
        reject(error)
      }
    })
  })
}

export function scaleCropParams(cropParams: Rect, scale: number) {
  return {
    x: Math.round(cropParams.x * scale),
    y: Math.round(cropParams.y * scale),
    width: Math.round(cropParams.width * scale),
    height: Math.round(cropParams.height * scale),
  }
}

export async function calculateCropForRatio({
  targetRatio,
  photo,
}: {
  targetRatio: number
  photo: Photo & { cropParams: Record<number, Crop> }
}) {
  const {
    width,
    height,
    thumbDimensions,
    lowResUrl,
    url,
    isLocal,
    faces,
    cropParams = {},
  } = photo
  let newCropParams
  try {
    const didUserCrop =
      Object.entries(cropParams).length > 0 &&
      [CROP_TYPE.MANUAL, CROP_TYPE.FIT].includes(
        Object.values(cropParams)[0].cropType
      )
    if (didUserCrop) {
      const [ratioToAdjustTo, cropToAdjustTo] = Object.entries(cropParams)[0]
      newCropParams = fitCropToAllRatios({
        ratiosToCalculate: [targetRatio],
        cropRect: cropToAdjustTo,
        currentAspectRatio: ratioToAdjustTo,
        imageWidth: width,
        imageHeight: height,
      })[targetRatio]
    } else {
      // Calculate cropParams by smart crop and scale them to fit the original image
      if (isLocal) {
        const smartCropResult = await calculateSmartCropFromUrl({
          imageUrl: lowResUrl,
          targetAspectRatio: targetRatio,
          faces,
          timeout: SMART_CROP_TIMEOUT,
        })
        newCropParams = scaleCropParams(
          smartCropResult.cropRect,
          width / thumbDimensions.low.width
        )
      } else if (lowResUrl) {
        const smartCropResult = await calculateSmartCropFromUrl({
          imageUrl: lowResUrl,
          targetAspectRatio: targetRatio,
          timeout: SMART_CROP_TIMEOUT,
          faces,
        })
        newCropParams = scaleCropParams(
          smartCropResult.cropRect,
          width / (extractThumbDimensionsFromUrl(lowResUrl)?.width ?? 0)
        )
      } else if (url) {
        // No scale required because it's the original url
        const smartCropResult = await calculateSmartCropFromUrl({
          imageUrl: url,
          targetAspectRatio: targetRatio,
          timeout: SMART_CROP_TIMEOUT,
          faces,
        })
        newCropParams = smartCropResult.cropRect
      }
    }
  } catch (e) {
    if (!(e instanceof ExternalError)) {
      logger.error('Failed to calculate smart crops for photo', e)
    }
  }

  return newCropParams || calcImageDefaultCrop(width, height, targetRatio)
}

const cropChangeThreshold = 10
const cropResultProps = ['width', 'height', 'x', 'y']

export function didCropChange<T extends Record<string, number>>(
  cropA: T,
  cropB: T
) {
  return cropResultProps.some((key) => {
    const a = cropA[key]
    const b = cropB[key]

    // We only count a crop if the change in any property is above the threshold
    // This is done because the cropping lib sometimes slight changes the crop without user interaction
    const distance = Math.abs(a - b)
    return distance > cropChangeThreshold
  })
}

export function getInitialCroppedArea(tile: Tile) {
  const { width, height, x, y, cropType } =
    tile.cropParams[tile.currentAspectRatio] || {}
  return { width, height, x, y, cropType }
}
